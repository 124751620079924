/*******navbar********/
.navbar{
 
  top: 0;
  width: 100%;
  

}
.active-link{
  
  background-color: brown;
}

  .btn1{
    border: 2px solid green;
    background-color: white;
    color: green;
    padding: 5px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px 5px 5px 5px;
  margin-left: 20px;
    
}
   
    .btn1:hover {
    background-color:green;
    color: white;
  }


  /**********contact us (contact info)***********/

  input[type=text]{
    width: 100%;
    padding: 12px;
    border: 1px solid black;
    margin-top: 6px;
    margin-bottom: 16px;
    border-radius:10px;
    box-shadow: 0px 10px 70px rgba(226, 102, 0, 0.15);

  
  }
  input[type=submit]{
    background-color: orange;
    
  }
  
  input[type=submit]:hover{
    background-color: green;
  }
  
textarea{
  box-shadow: 0px 10px 70px rgba(4, 236, 128, 0.15);
  margin-bottom: 16px;
  border-radius:10px;
  width: 100%;
}
  .buttons{
    background-color:orangered;
    color: white;
    padding: 10px;
    font-size: 18px;
    border:2px solid orange;
    border-radius: 20px;
   
  }
  .buttons:hover{
    background-color: orange;
  }

  .social i{
    transition: .5s all ease;
  }

.social i:hover{
  transform: rotate(45deg);
  background-color: whitesmoke;
 
  
}
.box{
  transition: .5s all ease;
}
.box:hover{
 
  border-left: 8px solid brown;
  border-top:8px solid brown ;
  background-color: orange;
}
#contact i{
  transition: .5s all ease;
}

#contact i:hover{
  transform: rotate(360deg);
  color: orange;
}

